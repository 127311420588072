@use './gap.theme' as gap-theme;
@use './reset.theme' as reset-theme;
@use 'guest-app-ui/scss/scss/gap-variables' as gap-variables;

@include gap-theme.standard-css-variables();
$theme: gap-theme.theme();

@include gap-theme.global-theme($theme);

.accesso-light-theme {
  @include gap-theme.component-theme($theme);
  @include reset-theme.theme($theme);

  background: #{gap-variables.$g6};
  color: #{gap-variables.$g1};

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
}
