/** Overwrite styles for recaptcha */

//  hide grecaptcha the badge
.grecaptcha-badge {
  visibility: hidden;
}

.recaptcha-container {
  font-size: 12px !important;
  a {
    font-size: 12px !important;
  }
}
