@use './styles/theme';
@use './styles/functions';
@use './styles/material/gap-fixes';

@use './styles/utilities/display.scss';
@use './styles/utilities/padding-and-margin.scss';
@use './styles/float.scss';
@use './styles/cursor.scss';
@use './styles/inputs.scss';
@use './styles/text.scss';
@use './styles/font.scss';
@use './styles/border.scss';
@use './styles/material/card.scss';
@use './styles/button.scss';
@use './styles/recaptcha.scss';
@use './styles/updates.scss';

@import '@angular/cdk/overlay-prebuilt.css';

.mat-form-field-infix {
  width: auto;
}

html,
body {
  min-height: 200px;
  margin: 0;
  padding: 0;
  font-size: 16px;
  overflow-x: hidden;
}

app-root,
main {
  height: 100%;
  width: 100%;
}

body {
  margin-bottom: em(10px);
}
