.material {
  &--card {
    &-xs {
      width: 100%;
      margin: 0 auto;

      @media (min-width: 400px) {
        width: 320px;
      }
    }
  }
}
