h3 {
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  margin-bottom: 16px !important;
}

.donation__banner,
.mdc-text-field__icon {
  line-height: 28px;
}

.donation__select {
  margin-bottom: 0px;
}

.mat-input-element {
  line-height: 44px;
  padding-right: 8px;
  letter-spacing: 0.44px;
  box-sizing: border-box;
}
