gap-button + gap-button {
  margin-bottom: 16px;
  margin-left: 0;
}

gap-icon {
  font-family: 'Material Icons';
}

gap-button-label {
  text-transform: none;
}
