/* give it extra specificity (app-root) because much of material components will override the display property since it gets injected after this file */
.display {
  &--inline {
    display: inline;

    &-block {
      display: inline-block;
    }
  }

  &--block {
    display: block;
  }

  &--none {
    display: none;
  }
}
