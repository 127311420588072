.input {
  &.--fill {
    width: 100%;
  }
}

input,
select {
  box-sizing: border-box;
  &:-webkit-autofill {
    background-color: #fff;
  }
}
/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
