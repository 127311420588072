$sizeMap: 4, 5, 10, 15, 16, 20, 24, 32;
$typeMap: 'margin', 'padding';
$directionMap: 'top', 'right', 'bottom', 'left';

/**
 * Margin and Padding utilities
 * Example: margin--top-5
 */
@each $type in $typeMap {
  .#{$type} {
    @each $size in $sizeMap {
      &--#{$size} {
        #{$type}: #{$size}px;

        @each $direction in $directionMap {
          &-#{$direction} {
            #{$type}-#{$direction}: #{$size}px;
          }
        }
      }

      &--#{$size}-all {
        #{$type}: #{$size}px;
      }
    }
  }
}