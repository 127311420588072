@use './theme' as theme;
@use 'sass:map';
@use 'guest-app-ui/scss/scss/gap-variables' as gap-variables;

.border {
  @each $direction in ('top', 'right', 'left', 'bottom') {
    &--#{$direction} {
      border-#{$direction}: 1px solid #{gap-variables.$g3};
    }
  }

  &--all {
    border: 1px solid #{gap-variables.$g3};
  }

  &--radius {
    overflow: hidden;
    border-radius: 4px;
  }
}
