.cursor {
  &--disabled {
    cursor: not-allowed;
  }

  &--pointer {
    cursor: pointer;
  }

  &--help {
    cursor: help;
  }
}

[role='button'] {
  cursor: pointer;
}
