@use 'guest-app-ui/scss/scss/gap-standard-theme';
@use 'guest-app-ui/scss/scss/gap-utilities';
@use 'guest-app-ui/scss/scss/gap-standard-setup';
@use 'guest-app-ui/scss/scss/gap-global';
@use 'guest-app-ui/scss/scss/gap-typography';
@use 'guest-app-ui/scss/components/gap-elevation/gap-elevation';
@use 'guest-app-ui/scss/scss/gap-color';
@use 'guest-app-ui/scss/scss/gap-variables';

@mixin standard-css-variables() {
  @include gap-standard-setup.standard-css-variables();
  @include custom-css-variables();
}

@mixin component-theme($theme) {
  @include gap-standard-setup.all-components($theme, true);
  @include gap-elevation.theme($theme);
}

@mixin global-theme($theme) {
  @include gap-global.theme($theme);
  @include gap-typography.theme($theme);
  @include gap-color.theme($theme);
}

@mixin custom-css-variables() {
  :root,
  ::before,
  ::after {
    --mdc-typography-font-family: 'Roboto', monospace, sans-serif;
    --gap-font-family: 'Roboto', monospace, sans-serif;
  }
}

@function theme() {
  $theme: gap-standard-theme.theme();
  @return gap-utilities.theme-validation($theme);
}
