@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Light.ttf') format('truetype'); /* For Roboto Light */
  font-weight: 300; /* Corresponds to Roboto Light */
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Regular.ttf') format('truetype'); /* For Roboto Regular */
  font-weight: 400; /* Corresponds to Roboto Regular */
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts//Roboto-Medium.ttf') format('truetype'); /* For Roboto Medium */
  font-weight: 500; /* Corresponds to Roboto Medium */
}

.font {
  &--xs {
    font-size: 0.75em;
  }

  &--md {
    font-size: 1.2em;
  }

  &--lg {
    font-size: 1.6em;
  }

  &--xl {
    font-size: 2em;
  }

  &--xxl {
    font-size: 3em;
  }

  &--bold {
    font-weight: bold;
  }

  /* FONT WEIGHTS */
  &--w {
    @each $weight in (300, 400, 500) {
      &-#{$weight} {
        font-weight: #{$weight};
      }
    }
  }
}
