@use 'media-size' as media-size;

.accesso-light-theme {
  .mat-flat-button {
    min-width: 100px;

    & + button {
      @media (min-width: media-size.$media-xs) {
        margin-left: 15px !important;
        margin-bottom: 0;
      }
      margin-bottom: 15px;
    }
  }
}
