@use 'theme' as theme;
@use 'sass:map';
@use 'guest-app-ui/scss/scss/gap-variables' as gap-variables;

.text {
  @each $direction in ('left', 'right', 'center') {
    &--#{$direction} {
      text-align: #{$direction};
    }
  }

  &--upper {
    text-transform: uppercase;
  }

  &--color {
    &-primary {
      color: #{gap-variables.$g1};
    }
  }
}
